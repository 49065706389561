<template>
  <div>
    <div class="paper_warp"
         v-for="(topic,index) in list"
         :key="index">
      <div class="has_children"
           v-if="topic.question_children">
        <div class="head">
          本题共{{topic.question_children.length}}小题
          <div>
            <!-- <span class="fixed_right fonts">
        
              <el-button type="text"
                         v-if="showOpertion"
                         class="fonts"
                         style="color:#FF6147"
                         @click="check(topic)">换题</el-button>
            </span>

            <span class="fixed_right fonts"
                  v-if="showChange">
              <el-button type="text"
                         class="fonts"
                         style="color:#3F9EFF"
                         @click="checkTopic(topic)">选择</el-button>
              <el-button type="text"
                         style="color:#FF6147"
                         class="fonts"
                         @click="diuqi(topic)">丢弃</el-button>
            </span> -->

          </div>
        </div>

        <div class="box-card">
          <div class="clearfix">
            题目：
          </div>
          <div class="topic_content"
               v-html="topic.question_content">
          </div>
          <div class="bottom_border"></div>
        </div>
        <Topic ref="TopicRef"
               v-for="item in topic.question_children"
               :key="item.answer_id"
               :item="item">
        </Topic>
      </div>

      <div class="warp"
           v-else>
        <Topic ref="TopicRef"
               :item="topic">
          <!-- <template v-slot:right_btn>
            <span class="fixed_right fonts">

              <span v-if="showOpertion"
                    class="fonts"
                    style="color:#FF6147"
                    @click="check(topic)">换题</span>
              <span class="fonts"
                    style="color:#3F9EFF"
                    @click="jiucuo(topic)">纠错</span>
            </span>
          </template> -->

          <template v-slot:right_btn_topic>
            <span class="fixed_right fonts"
                  v-if="showChange">
              <el-button type="text"
                         style="color:#3F9EFF"
                         @click="checkTopic(topic)">选择</el-button>
            </span>
          </template>
        </Topic>
      </div>
    </div>

  </div>
</template>

<script>
import Topic from './topic.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    Topic,
  },
  props: {
    // 接收题目，在使用组件的地方去循环
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    showOpertion: {
      type: Boolean,
      default: false
    },
    showChange: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    sort (row) {
      this.$parent.sort(row)
    },
    check (row) {
      this.$parent.check(row)
    },
    checkTopic (row) {
      this.$emit('checkTopic', row)
    },
    diuqi (row) {
      this.$emit('diuqi', row)
    },
  }
}
</script>

<style lang="scss" scoped>
.paper_warp {
  width: 95%;
  margin-top: 20px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  .has_children {
    .head {
      font-weight: bold;
      background: #eff8ff;
      padding: 10px 20px;
      position: relative;
      div {
        position: absolute;
        right: 15px;
        top: 0px;
      }
    }
  }
}

.box-card {
  padding-top: 10px;
  background: white;
  .clearfix {
    font-size: 18px;
    font-weight: bold;
    height: 18px;
    line-height: 18px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    margin: 20px 0;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
    padding-right: 20px;
  }
}

.fixed_right {
  position: absolute;
  right: 0;
  width: 120px;
  top: -5px;
}
.fonts {
  font-weight: bold;
  padding: 10px 10px;
  font-size: 16px !important;
}
</style>