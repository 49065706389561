import { instance } from '@/utils/http.js'
// 获取模板列表
export const getRuleList = function (params) {
  return instance({
    url: '/api/v2/paper/rule_list',
    method: 'get',
    params
  })
}

export const addRule = function (data) {
  return instance({
    url: '/api/v2/paper/rule_add',
    method: 'post',
    data
  })
}


// 根据id组卷规则录入详情
export const getRuleDetail = function (params) {
  return instance({
    url: 'api/v2/paper/rule_question_show',
    method: 'get',
    params
  })
}

// 删除组卷规则
export const delRule = function (data) {
  return instance({
    url: '/api/v2/paper/rule_del',
    method: 'post',
    data
  })
}

// 根据组卷ID查找相关题目
export const getPaper = function (data) {
  return instance({
    url: '/api/v2/paper/rule_question_paper',
    method: 'post',
    data
  })
}