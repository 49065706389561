<template>
  <div>
    <div class="left">
      <BASETOPICCOMMON ref="BASETOPICCOMMONREF"
                       :list="list" />
    </div>
    <div class="right">
      <scrollTo ref="scrollToRef"
                :list="childredList" />
    </div>
  </div>
</template>

<script>
// import tipicBody from '@/components/topic/index.vue'
import scrollTo from '@/components/scrollTo/index.vue'
import BASETOPICCOMMON from '@/components/BASE_PAPER_COMMON/index.vue'
import { getPaper } from '@/api/paperRuleTemplate.js'

export default {
  data () {
    return {
      // recordEduPaper: {},
      list: [],
      isActive: 0,
      domList: [],
      childredList: 0
    }
  },
  components: {
    scrollTo, BASETOPICCOMMON
  },
  mounted () {
    this.getInfo()
    document.body.background = '#F6F6F6'
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async getInfo () {
      const { data } = await getPaper({
        paper_rule_id: this.$route.query.paper_rule_id
      })
      this.list = data
      setTimeout(() => {
        var oFixed = document.getElementsByClassName('yuan')
        this.childredList = oFixed.length
      }, 1000);

      // console.log(data)
    },


    toMaodian (index) {
      this.isActive = index
      let anchorElement = document.getElementById('maodian' + index);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left {
  margin-right: 20px;
}
.right {
  margin-right: 10px;
}
</style>