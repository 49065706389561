<template>
  <div class="topic_info">
    <div class="card_head">
      <div style="color:#9EA1A4">
        <span class="yuan"
              v-if="item.answer_id">
          {{item.answer_id}}
        </span>
        <span class="source"
              v-if="item.question_source_year">
          题目来源：{{item.question_source_year}};
        </span>
        <span class="category"
              v-if="item.subject_id ==7 || item.subject_id ==11">
          题目类别：{{item.question_category_name}}
        </span>
        <span class="score">题目分数：</span><span style="color:#FE674E">{{item.question_score}}</span>
        <span class="kaodian"
              v-if="item.check_point">
          题目知识点：{{item.check_point}}
        </span>
        <span class="typeName"
              v-if="item.question_type_name">
          {{item.question_type_name}}</span>

        <slot name="right_btn_topic"></slot>
        <slot name="right_btn"></slot>

      </div>

    </div>

    <div class="box-card">
      <div class="clearfix">
        题目内容：
      </div>
      <div class="topic_content"
           v-html="item.question_content">
      </div>
      <div class="bottom_border"></div>
    </div>

    <div class="box-card"
         v-if="item.topic != 6">
      <div class="clearfix">
        <span>题目答案：</span>
      </div>
      <div class="topic_content"
           style="color:#FF6147;font-weight:bold"
           v-html="item.question_answer">
      </div>
      <div class="bottom_border"></div>
    </div>

    <div class="box-card"
         v-if="item.topic != 6"
         style="padding-bottom:15px">
      <div class="clearfix">
        <span>题目解释：</span>
      </div>
      <div class="topic_content"
           v-html="item.question_explain">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card_head {
  font-size: 16px;
  position: relative;
  display: flex;
  padding: 10px 10px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #eff8ff;
  div {
    width: 100%;
    position: relative;

    .yuan {
      display: inline-block;
      width: 28px;
      height: 28px;
      background: #68bcff;
      color: white;
      text-align: center;
      line-height: 28px;
      border-radius: 50%;
    }
    .source {
    }
    .category {
    }
    .score {
      color: #393b3d;
      font-weight: bold;
      margin-left: 20px;
    }
    .kaodian {
      display: inline-block;
      color: black;
      font-weight: bold;
      margin-top: 15px;
    }
    .typeName {
      display: inline-block;
      padding: 5px 15px;
      background: #68bcff;
      color: white;
      margin-left: 10px;
      border-radius: 20px;
    }
  }
}

.box-card {
  padding-top: 10px;
  background: white;
  .clearfix {
    font-size: 18px;
    font-weight: bold;
    height: 18px;
    line-height: 18px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    margin: 20px 0;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
    padding-right: 20px;
  }
}

.bottom_border {
  width: 98%;
  height: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 20px;
}
.fonts {
  font-weight: bold;
  background: #eff8ff;
  padding: 10px 20px;
  position: relative;
  font-size: 16px !important;
}
</style>